.getquotewrapper {
  background: url("./QuoteImg/backquote.jpg");
  padding-top: 120px;
  padding-bottom: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.firsthalfquote {
  z-index: 99;
  color: white;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.getquoteformwrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 10px;
  z-index: 99;
  height: fit-content;
}
.getquotebottom {
  display: flex;
  flex-direction: row;
  padding-top: 120px;
  margin: 0px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 100px;
}
.tickicon {
  color: green;
  width: 50px;
  height: 35px;
}
.getquotebtn {
  margin-left: "11px";
  margin-top: 23px;
  background-color: #b00000;
  color: white;
  border-radius: 12px;
  height: 42px;
  border: none;
}

.getquotebtn:disabled {
  background-color: darkgray;
}
.overallbtn {
  margin-left: 11px;
  margin-top: 23px;
  background-color: #b00000;
  color: white;
  border-radius: 12px;
}

.accordion-button {
  color: #b00000;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: #b00000;
  background-color: #f5f5f5;
}
.accordion-button::after {
  flex-shrink: 0;
  font-size: 30px;

  display: flex;
  content: "+";
  background-image: none;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.accordion-button:not(.collapsed)::after {
  flex-shrink: 0;
  font-size: 52px;

  display: flex;
  content: "-";
  background-image: none;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .getquotewrapper {
    padding-top: 50px;
  }
  .firsthalfquote {
    padding-bottom: 30px;
  }
  .getquotebottom {
    padding-top: 50px;
  }
  .bottomsecondhalf {
    padding-top: 50px;
  }
}
