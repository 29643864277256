
  .btnwrapper{   
    max-width: 500px;   
  }
.btnStyle{
 padding:10px;
 padding-left: 15px;
 padding-right: 15px;
 border-radius: 80px;
 border:none;
 color: white;
 background-color: rgb(83, 4, 4);
 margin-top: 10px;
 margin-bottom: 10px;
}
.btnStyle2{
  padding:10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 80px;
  border:none;
  color: white;
  background-color: rgb(173, 15, 15);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left:10px
 }
  .signCanvas{
    border: 1px solid gray;
    max-width: 500px;
    height: 200px;
  }
  .signImage{
    width: 11rem;
    height: 5rem;
  }
  .signSubmitted{
  
    margin-left: 3%;
    margin-bottom: 3%;
    font-size: 30px;
    color: rgb(184, 68, 39);
  }


  /* -------------------------------------------- */
  .text-center {
    width: 100%;
    position: relative;
    height: 100%;
}

.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));  
    right: calc(50% - (58px / 2));
    color: red;
}

#backdrop {
  position:fixed;
  top:0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgb(0,0,0,0.2);
}
.brokerAgreement{
  margin-top:6vh;
  padding-left: 5%;
  padding-right: 5%;
}
.drawText{
  color:rgb(170, 16, 16);
  margin-left:2%;
}
/* .canvas{
  margin-left:2%;
} */