.heading{
    color:#b00000;
    font-size: 50px;
    border-bottom: #b00000 4px solid;
 
}
.keyPoints{
    width: 20%;
    padding-left:40px;
    display:flex;
    flex-direction:column;
}


.mainContent{

     
        width: 73%; margin-right:20px;

}
.label{
  font-size: 24px;
  color:#b00000;
  font-Weight:500;
  margin-bottom: 10px;
    
}
.imIcons{
font-size: 17px;
color: #b00000
}
.hiIcons{
   font-size: 17px;
   color: #b00000
     

}

@media screen and (max-width: 1000px) {

.keyPoints {
    display: none;
}
 
.mainContent {

display: flex;
justify-content: center;
flex-direction: column;
    width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0px;

}
}

@media screen and (max-width: 600px) {
.heading{
    font-size:38px
}
}