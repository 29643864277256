.Sidebarmenuitem {
  display: block;
  block-size: 3rem;
  color: #b00000;
  justify-content: center;
  padding-top: 25px;
  text-decoration: none;
  padding-bottom: 40px;
  margin-left: 63px;
  margin-right: 58px;
  border-bottom: 2px solid #b00000;
  margin-bottom: 50px;
}
.Sidebarmenuitem:hover {
  color: white;
  background-color: #b00000;
}
