.Section1 {
  /* margin-top: 60px; */
  background-image: url("../Homepage/HomeImg/HomepageBanner.jpg");
  background-position: center;
  height: 500px;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  object-fit: contain;

  /* margin-left: 40px;
margin-right: 40px;
margin-top:40px */
}
.WelcomeSecImage {
  width: 359px;
}
.Section2 {
  margin: 0px;

  padding-bottom: 100px;
  padding-left: 0px;
  padding-right: 0px;
}
.sec2comp2 {
  display: flex;
  justify-content: flex-end;
}
.Section3 {
  background: #f5f5f5;
  padding-top: 40px;

  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sec3comp {
  margin-left: 30px;
  margin-right: 30px;
}
.inSec3Comp {
  color: #b00000;
  font-size: 1.5rem;
  margin-left: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
.sec4icon {
  color: #b00000;
  font-size: 5rem;

  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.Section5 {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-bottom: 40px;
}

.sec5heading {
  color: #b00000;
  display: flex;
  justify-content: center;
}
.sec5subheading {
  display: flex;
  justify-content: center;
}

.sec5imgcomp1 {
  justify-content: flex-end;
}

.sec5imgcomp2 {
  justify-content: flex-start;
}
.Section6 {
  padding-top: 50px;
  padding-bottom: 70px;
}
.sec6heading {
  color: #b00000;
  display: flex;
  justify-content: center;
}
.sec6subheading {
  display: flex;
  justify-content: center;
}
.sec6card {
  justify-content: space-evenly;
}
.getquotebtn {
  margin-left: "11px";
  margin-top: 23px;
  background-color: #b00000;
  color: white;
  border-radius: 12px;
  height: 42px;
  border: none;
}

.getquotebtn:disabled {
  background-color: darkgray;
}
@media screen and (max-width: 992px) {
  .sec5imgcomp1 {
    justify-content: center;
  }

  .sec5imgcomp2 {
    justify-content: center;
  }
  .sec2comp2 {
    justify-content: center;
  }
}
@media screen and (max-width: 505px) {
  .sec3comp {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 505px) {
  .WelcomeSecImage {
    max-width: 100%;
  }
}
