.CarrierSec1 {
  background-image: url("./CarrierImg/CarrierLoadkarma.jpg");
  background-repeat: no-repeat;
  -o-object-fit: contain;
  object-fit: contain;
  height: 500px;
  background-position: bottom;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}
.CarrierSec1comp1 {
  z-index: 22;
  position: relative;
  padding-left: 20px;
}
.inCarrierSec1 {
  margin: 0px;

  /* padding-bottom: 100px; */
  padding-left: 0px;
  padding-right: 0px;
}

.inAtLast {
  margin-top: 20px;
  font-size: 17px;
}

.CarrierSec2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 46px;
  padding-right: 46px;
}

.carrierform {
  margin: 0px;
  padding: 30px;
  background-color: rgb(128, 0, 0, 0.1);
}
.getquotebtn {
  margin-left: "11px";
  margin-top: 23px;
  background-color: #b00000;
  color: white;
  border-radius: 12px;
  height: 42px;
  border: none;
}

.getquotebtn:disabled {
  background-color: darkgray;
}
.goBtn{
  background-color: #b00000;
  color: white;
  border-radius: 12px;
  border: none;
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
  /* width:10%; */
}
.spanError{
  font-size: small;
  color: red;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .CarrierSec2 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .inAtLast {
    font-size: 11px;
  }
}
