.inputdiv{
    margin-bottom: 20px;
} 
.inputInquote{
    height:50px;
     padding-right: 7px;
     padding-left: 7px;
    background-color: #ffffff;
     border-radius: 10px;
}
.inputInquote2{
    height:50px;
    padding-right: 7px;
    padding-left: 7px;
   background-color: #f0f4f5;
    border-radius: 10px;
    /* margin-left: 10px; */
    margin-bottom: 20px;
}
.inputInquote_MCnumber{
    height:50px;
     padding-right: 7px;
     padding-left: 7px;
    background-color: #f0f4f5;
     border-radius: 10px;
     width: 100%;
}
.inputInquoteRequired{
     height:50px;
     padding-right: 7px;
     padding-left: 7px;
     background-color: #ffffff;
     border-radius: 10px;
     border-color: red;
     /* border-width: 4px; */
}
.requiredTheme{
    color: red;
    font-size: 12px;
    margin-top: 6px;

}


