.AboutSec1 {
  background-image: url("./AboutImg/LoadKarma_About\ Us_image.jpg");
  background-repeat: no-repeat;
  -o-object-fit: contain;
  object-fit: contain;
  height: 500px;
  background-position: bottom;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}

.AboutSec2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 0, 0, 0.1);
  padding-bottom: 60px;
  padding-top: 60px;
}

.AboutSec2 p {
  padding-left: 40px;
}
.AboutSec3 {
  width: 100%;
  padding-top: 100px;

  background-color: rgba(128, 0, 0, 0.1);
}

.AboutSec3end {
  padding-bottom: 100px;
}
@media screen and (max-width: 1000px) {
  .AboutSec2 {
    flex-direction: column;
  }
  .AboutSec2 p {
    padding-left: 0px;
  }
  .AboutSec3 {
    padding-top: 30px;
  }
  .AboutSec3end {
    padding-bottom: 30px;
  }
}
