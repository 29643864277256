.mainNavbarWrapper {
  position: sticky;
  width: 100%;
  box-shadow: 0px 8px 7px -2px rgba(153, 144, 144, 0.75);
  top: 0;
  background-color: white;
  z-index: 999999999;
}
a .current {
  text-decoration: none;
  color: blue;
}
.navtoplink {
  text-decoration: none;
  color: #58595b;
}
.navlink {
  text-decoration: none;
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.Topheader {
  color: #b00000;
}
.Topheader:hover {
  background-color: #b00000;
  color: white;
}
.navlink:hover {
  color: #b00000;
}
.navli:not(:last-child):hover {
  color: #b00000;
  border-bottom: solid 3px #b00000;
  transition: left 500ms ease-in-out, right 500ms ease-in-out;
  opacity: 1;
}
.navhome {
  display: flex;
  align-items: center;
}
.navbtn {
  color: white;
  background-color: #b00000;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
}

.navtop {
  height: 2.5rem;
  padding-right: 5rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.navli {
  text-decoration: none;
  margin-right: 17px;
  margin-left: 25px;
  display: flex;
  align-items: center;
}

.navtopitem {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
  font-size: 0.85rem;
  font-weight: 500;
}
.navbar {
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  padding: 0px;
}
.baricon {
  font-size: 1.5rem;
  padding: 0.54rem 1.2rem;
  vertical-align: top;
  border-radius: 10px;
  background-color: #f5f5f5;
  color: black;
}
.baricon:hover {
  color: #b00000;
}

.logoimg {
  width: 57%;
  max-width: 100%;
}

.last {
  width: 55%;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}
.bottom {
  height: 40px;
  background-color: #b00000;
}

.SidebarNav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 10;
}
.bottomnav {
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0px;
}
.bottomnavicon {
  width: 28px;
  height: 19px;
  vertical-align: text-bottom;
}
.lihover:hover {
  background-color: darkred;
}
.showSidenav {
  left: -100%;
}
.affix {
  top: 0;
  width: 100%;
  z-index: 9999 !important;
}
.barclose {
  display: flex;
  padding-top: 30px;
  justify-content: center;
  padding-bottom: 30px;
  font-size: 50px;
  color: grey;
}
.barclose:hover {
  color: grey;
}

.overlay{
  width: "100%";
   height: "100%" 
}



@media screen and (min-width: 1000px) and (max-width: 50000px) {
  .baricon {
    display: none;
  }
  .SidebarNav {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .last {
    display: none;
  }
  .navhome {
    display: none;
  }
  .navbar {
    flex-direction: row-reverse;
  }
  .middle {
    margin-left: 140px;
  }
  .navli {
    display: none;
  }
  .navlink {
    display: none;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1180px) {
  .navli {
    margin-left: 15px;
    margin-right: 0px;
  }
}
@media screen and (max-width: 420px) {
  .middle {
    width: 67%;
  }
  .navtopitem {
    font-size: 10px;
  }
}

@media screen and (max-width: 720px) {
  .middle {
    margin-left: 70px;
  }
}

@media screen and (max-width: 420px) {
  .middle {
    margin-left: 30px;
  }
}
