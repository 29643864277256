.ContactSec1 {
  background-image: url("../Carriers/CarrierImg/CarrierLoadkarma.jpg");
  background-repeat: no-repeat;
  -o-object-fit: contain;
  object-fit: contain;
  height: 500px;
  background-position: bottom;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactSecton2p {
  padding-top: 70px;
}

.getquotebtn {
  margin-left: "11px";
  margin-top: 23px;
  background-color: #b00000;
  color: white;
  border-radius: 12px;
  height: 42px;
  border: none;
}

.getquotebtn:disabled {
  background-color: darkgray;
}
