.shipSec1 {
  background-image: url("./ShipperImg//ShipperLoadkarma.jpg");
  height: 500px;
  background-position: bottom;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}
.shipSec1comp1 {
  z-index: 22;
  position: relative;
  padding-left: 20px;
}
.normal {
  display: flex;
  justify-content: space-around;
}
.reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
}
.Services {
  display: flex;

  margin-top: 10px;
  height: 400px;
}
.ShipperPhoto {
  max-width: 100%;
}

@media screen and (max-width: 992px) {
  .Services {
    display: flex;
  }
}
