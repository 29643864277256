.overallbtn{
    background-color: #b00000;
    color:white;
  font-weight: 600;
  width: auto;
}
.overallbtn:hover{
    color:white;
    background-color: red;

}
.overallbtn:focus{
    box-shadow: none;
}