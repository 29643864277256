.footerwrapper {
  background-color: #f5f5f5;
}
.footerheading {
  color: black;
  font-weight: 450;
}
.footerpara {
  color: #58595b;
  font-size: 0.95rem;
}
.footerlink {
  display: flex;
  justify-content: flex-start;
  height: 40px;
}

.footerli {
  color: #58595b;
  text-decoration: none;
}
.footerli:hover {
  color: #b00000;
}

.footericon {
  color: #b00000;
  text-decoration: none;
  padding-right: 7px;
}
.footericon:hover {
  color: #b00000;
  padding-right: 8px;
}

.footertop {
  padding-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
}

.footerUseful {
  padding-left: 60px;
}
@media screen and (max-width: 1000px) {
  .footerUseful {
    padding-top: 25px;
    padding-left: 10px;
  }
  .footerContact {
    padding-top: 25px;
  }

  .footerFollow {
    padding-top: 25px;
  }
}

@media screen and (max-width: 500px) {
  .footertop {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 794px) {
  .footerul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
