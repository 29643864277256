body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
p {
  color: #666666;
}
ul {
  text-decoration: none;
  list-style-type: none;
}
li {
  text-decoration: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
Link {
  text-decoration: none;
}
input:focus {
  outline: none;
}
input {
  border: 0px solid;
}
button:focus {
  outline: 0 !important;
  box-shadow: none;
}
button {
  box-shadow: none;
}
textarea:focus {
  outline: 0 !important;
}
textarea {
  border: 0px solid;
}

label {
  color: #b00000;
  font-weight: 500;
} 

li:hover{
  background-color: #e5e5e5;
  color: #333;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b00000;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

